import React from 'react';
import Head from 'next/head';

import QRCodeScreen from '@common-lib/modules/verras/screens/QRCodeStatus';

import { Page } from '@web/types';
import PageWrapper from '@web/components/layout/PageWrapper';

const QRCodePage: Page = () => {
	return (
		<>
			<Head>
				<title>Vrumona: Verras op het Terras: Landing</title>
			</Head>
			<PageWrapper>
				<QRCodeScreen />
			</PageWrapper>
		</>
	);
};

export default QRCodePage;
