import React from 'react';

import Box, { BoxProps } from '@ui/components/Box';
import { styled } from '@ui/styles';

import Footer from '@common-lib/components/layout/Footer';
import ErrorBoundary from '@common-lib/components/ErrorBoundary';

type Props = {
	hideFooter?: boolean;
	backgroundImage?: string;
	backgroundColor?: string;
} & Omit<BoxProps, 'ref'>;

const Wrapper = styled(Box, {
	shouldForwardProp: prop =>
		!['backgroundImage', 'backgroundColor'].includes(String(prop)),
})<{
	backgroundImage?: string;
	backgroundColor?: string;
}>(({ backgroundImage, backgroundColor }) => ({
	backgroundImage: backgroundImage ? `url('${backgroundImage}')` : undefined,
	backgroundColor,
	display: 'flex',
	flexDirection: 'column',
}));
const PageWrapper: React.FC<Props> = ({
	children,
	hideFooter,
	className,
	backgroundImage,
	backgroundColor,
	...props
}) => (
	<>
		<Wrapper
			component="main"
			className={className}
			backgroundImage={backgroundImage}
			backgroundColor={backgroundColor}
			{...props}
		>
			{/* <Box sx={{
				position: 'absolute',
				top: 5,
				left: -55,
				zIndex: 100
			}}>
				<Link href="/kras/QR1234" passHref>
					<Button variant="text" color="error">START</Button>
				</Link>
			</Box> */}
			<ErrorBoundary>{children}</ErrorBoundary>
		</Wrapper>
		{hideFooter ? null : <Footer />}
	</>
);

export default PageWrapper;
