import React from 'react';

import AppBar, { AppBarProps } from '@ui/components/AppBar';
import Toolbar from '@ui/components/Toolbar';
import Image from '@ui/components/Image';
import Box from '@ui/components/Box';
import { styled } from '@ui/styles';
import useNodeLayout from '@ui/hooks/useNodeLayout';

const Bar = styled(AppBar)<
	AppBarProps & {
		component?: React.ElementType;
	}
>(({ theme }) => ({
	top: 'auto',
	left: 'auto',
	right: 'auto',
	'& > .MuiToolbar-root': {
		minHeight: 67,
	},
	height: 67,
	bottom: 0,
	backgroundColor: theme.palette.background.paper,
	boxShadow: 'none',
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
	maxWidth: 280,
	width: '100%',
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'nowrap',
	justifyContent: 'space-between',
	alignItems: 'center',
	'& > *': {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
}));

const iphoneAddressBarHeight = 45;
const Footer: React.FC<React.PropsWithChildren> = ({ children }) => {
	const ref = React.useRef(null);
	const initialRef = React.useRef(0);
	const { top } = useNodeLayout(ref);
	React.useEffect(() => {
		if (typeof window !== 'undefined') {
			const offset = window.screen.availHeight - window.innerHeight;
			if (!initialRef.current || offset < initialRef.current) {
				initialRef.current =
					window.screen.availHeight - window.innerHeight;
			}
			if (offset <= iphoneAddressBarHeight) {
				// assume address bar is hidden
				document.documentElement.style.setProperty(
					'--safe-area-inset-bottom',
					'0px',
				);
			} else {
				document.documentElement.style.setProperty(
					'--safe-area-inset-bottom',
					`${Math.max(
						window.screen.availHeight -
							window.innerHeight -
							Math.min(
								initialRef.current,
								iphoneAddressBarHeight,
							),
						0,
					)}px`,
				);
			}
		}
	}, [top]);
	return (
		<Bar
			color="primary"
			sx={{
				maxWidth: 'sm',
			}}
			component="footer"
			ref={ref}
		>
			<Toolbar sx={{ maxWidth: 'sm' }}>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="center"
					width="100%"
				>
					<LogoWrapper>
						<Image
							priority
							alt="ranja-logo"
							src="/logo/logo-ranja.png"
							width={42}
							height={38}
						/>
						<Image
							priority
							alt="rivella-logo"
							src="/logo/logo-rivella.png"
							width={59}
							height={30}
						/>
						{/* <Image
						priority
						alt="russel-co-logo"
						src="/logo/logo-russelco.png"
						width={42}
						height={42}
					/> */}

						<Image
							priority
							alt="royal-club-logo"
							src="/logo/logo-royalclub.svg"
							width={40}
							height={40}
						/>
					</LogoWrapper>
				</Box>
			</Toolbar>
		</Bar>
	);
};

export default Footer;
