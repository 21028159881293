import React from 'react';
import dynamic from 'next/dynamic';
import { useSpring, animated } from '@react-spring/web';
import { UserType } from '@interfaces';

import { styled } from '@ui/styles';
// import Link from '@ui/components/Link';
import Text from '@ui/components/Text';
import Button from '@ui/components/Button';
import Box from '@ui/components/Box';

const TermsModal = dynamic(
	() => import('@common-lib/modules/account/components/TermsModal'),
);
// const TheLink = styled(Link)(({ theme }) => ({
// 	fontSize: '0.5625rem',
// 	letterSpacing: '0.0625rem',
// 	lineHeight: '0.5625rem',
// 	color: '#ffffff',
// 	fontFamily: theme.fontFamily.gilroy,
// 	textDecoration: 'none',
// }));
const TheText = styled(Text)(({ theme }) => ({
	fontSize: '0.5625rem',
	letterSpacing: '0.0625rem',
	lineHeight: '0.5625rem',
	color: '#ffffff',
	fontFamily: theme.fontFamily.gilroy,
	textDecoration: 'none',
	cursor: 'pointer',
}));

const AnimatedBox = styled(animated.div)(({ theme }) => ({
	textAlign: 'center',
	paddingTop: theme.spacing(1.5),
	paddingBottom: theme.spacing(1),
	zIndex: 1,
	position: 'relative',
}));

const PrivacyLink: React.FC<{
	isConsumer: boolean;
	bgColor?: string;
	showButton?: boolean;
}> = ({ isConsumer, bgColor, showButton }) => {
	const [open, setOpen] = React.useState(false);
	const handleToggleModal = React.useCallback(() => {
		setOpen(prev => !prev);
	}, []);
	const handleMore = React.useCallback(() => {
		window.location.assign(
			isConsumer
				? 'https://www.vrumona.nl/terrasactie'
				: 'https://www.vrumona.nl/verrasophetterras-actievoorwaarden',
		);
	}, []);
	const style = useSpring({
		from: {
			opacity: 0,
		},
		to: {
			opacity: 1,
		},
		delay: 900,
	});
	return (
		<>
			<TermsModal
				userType={isConsumer ? UserType.CONSUMENT : UserType.KROEG}
				modalTitle="Actievoorwaarden"
				open={open}
				hideButton
				onClose={handleToggleModal}
				bgColor={bgColor}
			/>
			<AnimatedBox style={style}>
				{/* <TheLink href="/privacybeleid">
				Actievoorwaarden
			</TheLink> */}
				{showButton ? (
					<Box pb={2}>
						<Button
							onClick={handleMore}
							sx={{ mt: 2 }}
							variant="contained"
							size="large"
							color="secondary"
							className="verras-win-scan-new"
						>
							MEER WETEN
						</Button>
					</Box>
				) : null}
				<TheText onClick={handleToggleModal}>Actievoorwaarden</TheText>
			</AnimatedBox>
		</>
	);
};

export default PrivacyLink;
