import React from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useRouter } from 'next/router';

import { Box, Button, Text, Image } from '@ui/components';
import { styled } from '@ui/styles';

import TopCurveBox from '@common-lib/modules/landing/components/TopCurveBox';
import PrivacyLink from '@common-lib/components/layout/PrivacyLink';

const AnimatedBox = styled(animated.div)(({ theme }) => ({
	textAlign: 'center',
	// paddingTop: theme.spacing(2),
	paddingBottom: theme.spacing(1),
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',
	justifyContent: 'center',
	backgroundImage: `url('/images/background.webp')`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	borderRadius: 32,
	backgroundPosition: 'top',
	margin: 14,
	// iphone only
	// '@supports (-webkit-touch-callout: none)': {
	// 	paddingTop: theme.spacing(3),
	// 	flex: '0 0 auto',
	// },
}));
const HeadingWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	textAlign: 'center',
	width: '100%',
	zIndex: 5,
}));
const TheText = styled(Text)(({ theme }) => ({
	fontSize: '2.25rem',
	lineHeight: '1.9375rem',
	fontWeight: 600,
	fontFamily: theme.fontFamily.ceraCY,
}));
const TheImage = styled(Image)(({ theme }) => ({
	width: '90%',
	height: '100%',
}));

// const getMessage = (status: QRCodeStatus) => {
// 	switch (status) {
// 		case QRCodeStatus.EXISTS:
// 		case QRCodeStatus.CAPTURED:
// 			return 'Wekom, je hebt een geldige code gescand';
// 		case QRCodeStatus.COMPLETE:
// 			return 'Deze code is al gebruikt';
// 		case QRCodeStatus.NOTFOUND:
// 			return 'Deze code bestaat niet';
// 		default:
// 			return '';
// 	}
// };

const QRCodeScreen: React.FC = () => {
	const { replace } = useRouter();
	const props = useSpring({
		from: { opacity: 0, marginTop: 200 },
		to: { opacity: 1, marginTop: 14 },
		delay: 900,
	});
	const imageBoxProps = useSpring({
		from: { opacity: 0.5 },
		to: { opacity: 1 },
		delay: 800,
	});
	const handleClick = React.useCallback(() => {
		replace('/verrassen/registreren');
	}, []);
	return (
		<>
			<AnimatedBox style={props} className="wrapper-box bt-margin">
				<TopCurveBox pt={4}>
					<animated.div style={imageBoxProps}>
						<TheImage
							priority
							alt="keyvisual-business"
							src="/images/keyvisual-business-2.webp"
							width={322}
							height={338}
						/>
					</animated.div>
				</TopCurveBox>
				{/* <Box flex="1" display="flex" /> */}
				<HeadingWrapper>
					<Box maxWidth={250}>
						<TheText variant="h1" textTransform="uppercase">
							Win je eigen solar bar
						</TheText>
					</Box>
				</HeadingWrapper>
				<Box display="flex" flexDirection="row" justifyContent="center">
					<Box maxWidth={240} pt={2}>
						<Text
							variant="h3"
							component="p"
							textTransform="uppercase"
							gutterBottom
							color="text.primary"
						>
							DOE MEE & WIE WEET STAAT HIJ BINNENKORT OP JOUW
							TERRAS TE SHINEN!
						</Text>

						<Box pt={2}>
							<Button
								variant="contained"
								color="secondary"
								onClick={handleClick}
							>
								DIT WIL IK
							</Button>
						</Box>
						<Box pb={1} pt={0.5}>
							<PrivacyLink isConsumer={false} />
						</Box>
					</Box>
				</Box>
			</AnimatedBox>
		</>
	);
};

export default QRCodeScreen;
